html,
body {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}
#root {
  height: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

select::-ms-expand {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
